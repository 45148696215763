/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Micro meetup site."), "\n", React.createElement(_components.p, null, "A site/app where you:"), "\n", React.createElement(_components.p, null, "Enter a neighborhood, some proposed activities, and a time you will be around.\nThis is publicly viewable and others either message you or just show up."), "\n", React.createElement(_components.p, null, "The point is to discover other people with similar interests in a city."), "\n", React.createElement(_components.p, null, "You can have multiple profiles, so if you are interested in playing some sports in the park you\ncan use your sports profile to show what equiment you have and what games you’re interested in playing."), "\n", React.createElement(_components.p, null, "If you work in one neighborhood, but live in another you can have a lunch/coffee business profile\nto see if someone would like to meet for coffee or food in the area where you work. This could be for\npurerly social reasons or for more business-related, an example would be if you freelance you can\nput what skills you have and say what type of work you’re looking for. Or if you’d like to collaborate\non a project you likewise would put what skills you have and what complementary skillsets you’re looking\nfor."), "\n", React.createElement(_components.p, null, "The idea is that these “meetings” would be super fast to create and for activities you do already so there\nis no downside if no one shows up."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
